import { CompanyConfig, flagsForPublishers } from "@metranpage/core-interfaces";

const config: CompanyConfig = {
  deployedUrl: "https://onebook-test.metranpage.com/",
  ga: "G-7XRT391W56",
  companyUuid: "ac45d151-ee22-4cd2-a850-2b154d13507c",
  availableLanguages: [
    { visibleName: "Русский", iconSrc: "/assets/img/flag-ru.svg", value: "ru" },
    { visibleName: "English", iconSrc: "/assets/img/flag-gb.svg", value: "en" },
  ],
  downloadFileTemplate: "{filename}",
  color: "#e02379",
  socials: [],
  flags: {
    ...flagsForPublishers,
    isPurchasesAvailable: false,
    isEditionNoticeAvailable: false,
    isCoverEditorEnabled: true,
  },
};
export { config };
